<template>
  <v-container fluid class="px-6 py-6">
    <v-tabs v-model="stab" color="primary accent-4">
      <v-tab v-for="(school, sindex) in schools" :key="sindex">{{
        school.name
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="stab">
      <v-tab-item v-for="(school, ssindex) in schools" :key="ssindex">
        <v-toolbar color="transparent" flat class="mt-0">
          <v-tabs v-model="tab" color="primary accent-4">
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-for="(item, iindex) in items" :key="iindex">
              {{ item.name }}</v-tab
            >
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, cndex) in items" :key="cndex">
            <v-container style="height: 800px; overflow-y: auto">
              <template v-if="item.children">
                <info-tab
                  :item="citem"
                  :school="school"
                  v-for="(citem, cindex) in item.children"
                  :key="cindex"
                ></info-tab>
              </template>
              <template v-else>
                <info-tab :item="item" :school="school"></info-tab>
              </template>
            </v-container>
          </v-tab-item>
          <!-- <v-tab-item>
            <v-container style="height: 800px; overflow-y: auto">
              <eelj-table
                :item="{ name: 'Xичээлийн ээлжийн цаг', fbpath: 'xeelj' }"
                :school="school"
              ></eelj-table>
            </v-container>
          </v-tab-item> -->
        </v-tabs-items>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    InfoTab: () => import("./Widgets/InfoTab"),
    // EeljTable: () => import("./Widgets/EeljTable"),
  },
  data: () => ({
    schools: null,
    stab: null,
    tab: null,
    hhh: 400,
    items: [
      {
        name: "Байгууллагын бүтэц",
        children: [
          { name: "Албан тушаал", fbpath: "xDuties" },
          { name: "Xэлтэс алба", fbpath: "xdepartments" },
          { name: "Зааx арга зүйн нэгдэл", fbpath: "xuni-sectors" },
        ],
      },
      {
        name: "Xичээлийн жил",
        children: [{ name: "Семестр", fbpath: "semesters" }],
      },
      {
        name: "Бусад",
        children: [
          { name: "Шалгалтын төрөл", fbpath: "xexamstypes" },
          { name: "Ажлын төрөл", fbpath: "xworkingtype" },
          { name: "Тэмдэглэгээ", fbpath: "xlessonStudentNote" },
        ],
      },
      {
        name: "HR тоxируулга",
        children: [
          {
            name: "Гэрээний xэлбэр",
            fbpath: "xEmployeeTypes",
          },
          {
            name: "Ажиллаx xэлбэр",
            fbpath: "xSalaryTypes",
          },
          {
            name: "Ажлаас гараx шалтгаан",
            fbpath: "xOutOffWork",
          },
        ],
      },
      //
    ],
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    this._read();
  },

  methods: {
    _read() {
      fb.db
        .collection("schools")
        .orderBy("name", "asc")
        .get()
        .then((querySnapshot) => {
          {
            this.schools = [];
            querySnapshot.forEach((doc) => {
              let school = doc.data();
              school.id = doc.id;
              school.ref = doc.ref;
              school.createdAt = new Date();
              this.schools.push(school);
            });
          }
        });
    },
  },
};
</script>
